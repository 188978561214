<template>
  <div v-if="orderInfo.id * 1 > 0">
    <NavBar class="nav-bar">
      <div slot="title" v-if="orderInfo.status * 1 === 4">已驳回</div>
      <div slot="title" v-else-if="orderInfo.status * 1 === 3">已完成</div>
      <div slot="title" v-else>退货中</div>
    </NavBar>
    <!-- 订单内容 -->
    <div class="content-layout">
      <!-- 退款步骤 -->
      <div class="countdown-card" v-if="orderInfo.status <= 3">
        <div class="font-text">请等待商家处理</div>
        <van-count-down :time="time" />
        <van-steps :active="orderInfo.status" active-color="#E61F18">
          <van-step>退货申请</van-step>
          <van-step>商家处理</van-step>
          <van-step>同意退货</van-step>
          <van-step>退款成功</van-step>
        </van-steps>
      </div>
      <div class="shop-prompt" v-if="orderInfo.status <= 3">
        <p class="font-text">商家已收到您的退货申请，正在等待商家审核。</p>
        <span class="prompt-content">
          商家同意或者超时未处理，系统将退款给您。如果商家拒绝，您可以修改退款申请后再次发起，商家会重新处理重新处理
        </span>
      </div>
      <!-- 商品内容 -->
      <div class="goods-content">
        <div class="content-card">
          <!-- 店铺名称 -->
          <div class="shop-title-left">
            <!-- <van-icon name="shop-o" /> -->
            <img src="~@/assets/common/store_icon@2x.png" class="shop-img" />
            <span class="title-text">供应商：{{ orderInfo.shop_name }}</span>
            <van-icon name="arrow" />
          </div>
          <!-- 商品信息 -->
          <div class="goods-info">
            <div class="goods-images-box">
              <div class="buy-details">
                <div class="goods-img-box">
                  <img :src="orderInfo.goods_img" class="goods-img" />
                </div>
                <div class="sku-info">
                  <div>{{ orderInfo.goods_name }}</div>
                  <span class="specifications">{{ orderInfo.item_key }}</span>
                </div>
              </div>
            </div>
            <div class="price-quantity">
              <div class="price">
                <span>￥</span>
                <span>{{ toInteger(orderInfo.price) }}</span>
                <span>{{ twoFloating(orderInfo.price) }}</span>
              </div>
              <div class="quantity">x{{ orderInfo.goods_num }}</div>
            </div>
          </div>
          <!-- 总价 -->
          <div class="total-price">
            <div class="layout">
              <span>商品总价</span>
              <span>￥{{ twoNum(orderInfo.total_money) }}</span>
            </div>
            <div class="layout">
              <span>配送费</span>
              <span class="courier-price">￥{{ orderInfo.postage }}</span>
            </div>
            <div class="layout">
              <span>实付金额</span>
              <span class="sum-price"
                >￥{{ twoNum(orderInfo.total_money) }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- 订单客服 -->
      <div class="order-info-card">
        <div class="order-code layout">
          <span>订单编号：</span>
          <span class="color">{{ orderInfo.order_sn }}</span>
          <div class="copy icon-copy" :data-clipboard-text="orderInfo.order_sn">
            复制
          </div>
        </div>
        <div class="layout">
          <span>下单时间：</span>
          <span class="color">{{ orderInfo.o_created_at }}</span>
        </div>
        <div>
          <div class="layout">
            <span>支付方式：</span>
            <span class="color">{{ orderInfo.pay_type_str }}</span>
          </div>
          <div class="layout">
            <span>退货原因：</span>
            <span class="color">{{ orderInfo.return_msg }}</span>
          </div>
        </div>
        <div class="layout">
          <span>退货金额：</span>
          <span class="color text-color"
            >￥{{ twoNum(orderInfo.total_money) }}</span
          >
        </div>
        <div class="layout">
          <span>申请时间：</span>
          <span class="color">{{ orderInfo.created_at }}</span>
        </div>
        <div class="order-time order-code layout">
          <span>退货编码：</span>
          <span class="color">{{ orderInfo.return_sn }}</span>
          <div
            class="copy icon-copy"
            :data-clipboard-text="orderInfo.return_sn"
          >
            复制
          </div>
        </div>
        <div class="service" @click="contactService">
          <div class="service-img-box">
            <img src="~@/assets/common/kefu_icon@2x.png" class="service-img" />
          </div>
          <span class="contact">联系客服</span>
        </div>
      </div>
      <!-- <div>
        <GoodsShowLabel :tagId="0" :sendType="3">
          <div slot="detailsTitle" class="recommend">-为你推荐-</div>
        </GoodsShowLabel>
      </div> -->
    </div>

    <!-- 提示弹出层 -->
    <PromptPopup
      :showPopup="showPopup"
      :msg="showMsg"
      @popupFn="popupFn"
    ></PromptPopup>
  </div>
</template>

<script>
import PromptPopup from "@/components/prompt-popup/prompt-popup";
import NavBar from "@/layout/nav-bar/nav-bar";
// import GoodsShowLabel from "@/components/goods-show/goods-show-label";
import { getReturnInfo } from "@/api/order";
import { Toast } from "vant";
import Clipboard from "clipboard";
import tools from "@/common/js/tools";
export default {
  name: "my-order-details",
  components: {
    NavBar,
    // GoodsShowLabel,
    PromptPopup,
  },
  data() {
    return {
      time: 30 * 60 * 60 * 1000,
      active: 1,
      showPopup: false,
      showMsg: "",
      orderId:
        this.$route.query.orderId === undefined ? 0 : this.$route.query.orderId,
      orderInfo: { id: 0 },
      shopId: this.$route.query.shopId || 0,
    };
  },
  mounted() {
    console.log(this.shopId);
    this.getReturnInfo();
    const clipboard = new Clipboard(".icon-copy");
    clipboard.on("success", (e) => {
      Toast.success("复制成功！");
    });
  },
  methods: {
    contactService() {
      this.$router.push({
        name: "information",
        query: { shopId: this.shopId, groupType: 2 },
      });
    },
    switchFn(showType) {
      //显示提示弹框
      this.showType = showType;
      switch (showType) {
        case 1:
          this.showMsg = "是否取消此订单";
          break;
        case 2:
          this.showMsg = "确认收到货了吗？";
          break;
        default:
      }
      this.showPopup = true;
    },
    popupFn(bool) {
      // 弹框回调通知
      this.showPopup = false;
      if (bool) {
        switch (this.showType) {
          case 1:
            //执行取消订单操作
            this.cancelOrder();
            break;
          case 2:
            //执行取消订单操作
            this.setDelivery();
            break;
          default:
        }
      }
    },

    integerNum(num) {
      // 获取价格整数部分
      num = this.twoNum(num) + "";
      const result = num.substring(0, num.indexOf(".") - 1);
      return result;
    },
    floorNum(num) {
      // 获取价格小数部分
      return tools.twoFloating(num);
      // num = this.twoNum(num) + "";
      // const result = num.substring(num.indexOf("."));
      // return result;
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    async getReturnInfo() {
      const ret = await getReturnInfo({ return_id: this.orderId });
      if (ret.code * 1 == 1) {
        this.orderInfo = ret.data;
        this.time = this.orderInfo.created_time;
      }
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数，自动补零，保留两位小数
      return tools.twoFloating(num);
      // let price = "";
      // price = String(num).split(".")[1];
      // if (price !== undefined && price.length === 1) {
      //   price = `.${price}0`;
      // } else {
      //   price === undefined ? (price = ".00") : (price = `.${price}`);
      // }
      // return price;
    },
  },
};
</script>

<style lang="less" scoped>
.nav-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  z-index: 2;
}

.content-layout {
  padding: 46px 12px 20px 12px;
}
// 请等待处理
/deep/.van-step__title {
  font-size: 14px;
}
.font-text {
  font-size: 17px;
  margin-bottom: 5px;
}
.countdown-card {
  background-color: #fff;
  border-radius: 16px;
  padding: 12px;
  margin-top: 12px;
}
.shop-prompt {
  background-color: #fff;
  padding: 12px;
  border-radius: 16px;
  margin-top: 12px;
  .prompt-content {
    color: #aaaaaa;
    font-size: 14px;
  }
}
// 待付款
.await-pay {
  display: flex;
  justify-content: start;
  align-items: center;
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: 600;

  .img-box {
    width: 30px;
    margin-right: 10px;

    .img {
      display: block;
      width: 100%;
    }
  }
}

// 订单卡片样式
.goods-content {
  margin-top: 12px;
}

.content-card {
  border-radius: 16px;
  padding: 12px;
  background-color: #fff;

  .shop-title-left {
    display: flex;
    align-items: center;
    font-size: 17px;
    color: #c6c6c6;
    .shop-img {
      display: block;
      width: 21px;
      height: 21px;
    }

    .title-text {
      margin: 0 4px;
      font-weight: 500;
      color: #000;
    }
  }
}

// 商品信息
.goods-info {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
  .goods-images-box {
    overflow-x: scroll;
  }
  .buy-details {
    display: flex;
    justify-content: center;
    .goods-img-box {
      width: 90px;
      .goods-img {
        display: block;
        border-radius: 6px;
        width: 90px;
        height: 90px;
      }
    }

    .sku-info {
      height: 100%;
      margin-left: 12px;
      font-size: 17px;
      div {
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 20px;
      }
      .specifications {
        padding: 4px 13px;
        background-color: #f5f5f5;
        border-radius: 6px;
        color: #6f6f6f;
        font-size: 14px;
        display: -webkit-box; /**对象作为伸缩盒子模型展示**/
        -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
        -webkit-line-clamp: 2; /**显示的行数**/
        overflow: hidden; /**隐藏超出的内容**/
        word-break: break-all; /** 英语字母设定问题，它不会换行在代码中加入强制换行 可以解决这个问题**/
      }
    }
  }

  .price-quantity {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .price {
      font-size: 16px;
    }

    .quantity {
      margin-top: 12px;
      color: #aaaaaa;
    }
  }
}

.text-color {
  color: #e61f18 !important;
}
.total-price {
  .layout {
    display: flex;
    justify-content: space-between;
    padding: 6px 0;
    font-size: 16px;
    .courier-price {
      color: #6f6f6f;
    }

    .sum-price {
      color: #e61f18;
    }
  }
}

// 订单客服
.order-info-card {
  padding: 12px;
  background-color: #fff;
  margin-top: 12px;
  border-radius: 16px;

  .color {
    color: #6f6f6f;
    padding-left: 20px;
    font-weight: normal;
  }

  .layout {
    font-size: 16px;
    padding-bottom: 10px;
  }

  .order-code {
    display: flex;
    align-items: center;

    .copy {
      margin-left: 10px;
      color: #e61f18;
      border: 1px solid #e61f18;
      border-radius: 10px;
      padding: 2px 6px;
      font-size: 12px;
    }
  }

  .order-time {
    border-bottom: 1px solid #ededed;
  }

  .service {
    padding: 12px 12px 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    .service-img-box {
      width: 20px;

      .service-img {
        display: block;
        width: 100%;
      }
    }

    .contact {
      color: #aaaaaa;
      margin-left: 10px;
    }
  }
}
.recommend {
  font-size: 18px;
}
.bottom-button {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  padding: 10px 12px;

  .button-box {
    padding: 0 10px;

    .van-button--normal {
      padding: 20px;
    }
  }
}
.van-count-down {
  font-size: 16px;
  color: #aaaaaa;
}
</style>
